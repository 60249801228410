<template>
  <img class="unselect" @click="naverLogin" src="/static/icon/ic_naver_circle.svg">
</template>

<script>
  export default {
    name: "BtnSigninNaver",
    created() {
      if (location.hash.indexOf('#access_token')>-1) {
        let access_token = location.hash.replace('#access_token=', '').split('&')[0];
        const domain = location.origin.indexOf('localhost') >-1 ? 'http://localhost:8001' : 'https://master-dev.launchpack.co.kr:1111';
        location.replace(`${domain}/auth/user/signin/naver/platformfy?access_token=${access_token}`);
      }
    },
    methods: {
      naverLogin() {
        const domain = encodeURIComponent(location.origin);
        const url = `https://nid.naver.com/oauth2.0/authorize?response_type=token&client_id=lbTbNyjNo8ZGtFSxTiQs&redirect_uri=${domain}/signin`;
        //console.log(url)
        location.replace(url);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  img
    width 52px
    height 52px
</style>
